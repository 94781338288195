import {Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EcaseHttpService} from '../services/ecase-http.service';
import {ECaseUtils} from '../eCaseUtils';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../services/language.service';

@Pipe({
  name: 'eCaseTranslate',
  pure: false
})
export class ECaseTranslate implements PipeTransform {

  constructor(private route: Router, private activatedRoute: ActivatedRoute,
              private translateService: TranslateService, private eCaseHttpService: EcaseHttpService,
              private languageService: LanguageService) {
  }

  transform(key: string, keepTrack = true): string {
    if (key == null || key.trim() == '') {
      return '';
    }
    if (keepTrack) {
      if (this.eCaseHttpService.trackKeysPerPageArray.currentPath !== this.route.url) {
        this.eCaseHttpService.trackKeysPerPageArray.keys = [];
      }
      // don't add to the track empty keys or keys that are not codes
      if (key.trim() !== '' && key.includes('.')) {
        this.eCaseHttpService.trackKeysPerPageArray.keys.push(key);
      }
    }
    this.eCaseHttpService.trackKeysPerPageArray.currentPath = this.route.url;
    let translation = ECaseUtils.getTranslatedValueFromKey(this.translateService, key);
    if (translation === '') {
      for (const langItem of this.languageService.languageList) {
        translation = ECaseUtils.getTranslatedValueFromKey(this.translateService, key, langItem.id);
        if (translation !== '') {
          return translation;
        }
      }
      return key;
    }
    return translation;
  }


}
